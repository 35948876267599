export const ALL = "ALL"
export const ALL_OFFICES_SWE = 'ALLA KONTOR'
export const COUNTER = 'COUNTER'
export const MEASUREPOINT = 'MEASUREPOINT'
export const ADDRESS = 'ADDRESS'
export const ESTATE = 'ESTATE'
export const OWNER = 'OWNER'
export const USER = "USER"
export const OFFICE = "OFFICE"
export const PARSE_ERROR = 'PARSE_ERROR'
export const CONSUMPTION = 'CONSUMPTION'
export const COST = 'COST'
export const EMISSION = 'EMISSION'
export const READING = 'READING'
export const MIN_TEMP = 'MIN_TEMP'
export const MAX_TEMP = 'MAX_TEMP'
export const CURRENCY_SEK = 'SEK'
export const NOT_SET = 'NOT_SET'
export const EMISSION_KG_CO2 = 'KG CO2'
export const EMISSION_KG_CO2e = 'KG CO2e'
export const EXTERNAL_IMPORT_FAILED = 'EXTERNAL_IMPORT_FAILED'
export const MWH = "MWh"
export const KWH = "kWh"
export const LITER = "Liter"
export const M3 = "M3"
export const M_EXP_2 = "m²"
export const M_EXP_3 = "m³"
export const COST_TARGET = 'COST_TARGET'
export const CSV = 'CSV'
export const PDF = 'PDF'
export const EXTERNAL = 'EXTERNAL'
export const HEAT_EXCHANGER = 'HEAT_EXCHANGER'
export const TEXT = 'TEXT'
export const DROPDOWN = 'DROPDOWN'
export const UNDEFINED_SWE = 'Odefinerad'
export const LOA = 'LOA'
export const ATEMP = 'ATEMP'
export const NODE_WRITE = 'Node.Write'
export const DEVIATION_READ = 'Deviation.Read'
export const CUSTOMER = 'CUSTOMER'
export const CUSTOMERS = 'CUSTOMERS'
export const CUBIC_METERS_SWE = 'Kubikmeter'
export const WATER_SWE = "Vatten"
export const AREA = 'AREA' 
export const UPN = "UPN"
export const SAVEUP_TOKEN_KEY = "SaveUp.authToken";
export const PASSWORD_UPDATED = "PASSWORD_UPDATED";
export const categoryConstants = [
  {value: 'ALL', text: 'Alla'},
  {value: 'OTHER', text: 'Avvikelse'},
  {value: 'RESET', text: 'Mätarbyte/Runtslagning'},
  {value: 'LOWER_CONSUMPTION', text: 'Minskad förbrukning'},
]
export const controlTypeConstants = [TEXT, DROPDOWN]
export const HEAT_EXCHANGER_TYPES = [
  {name: 'ODEFINERAD', value: 0},
  {name: 'ROTERANDE', value: 70},
  {name: 'PLATT', value: 60},
  {name: 'BATTERI', value: 45},
  {name: 'HEATPIPE', value: 35},
]
