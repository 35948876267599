import {Reducer} from 'redux'
import {Role} from '../../shared/enums/Role'
import {IAuth, ILoginState} from './loginInterfaces'
import {LOGIN, LOGOUT, LOGIN_SUCCEDED, LOGIN_FAILED, LOGGING_IN, LOGIN_UNAUTHORIZED, AAD_LOGIN_LOADING, AAD_LOGIN_SUCCEDED, AAD_LOGIN_FAILED, SET_AAD_LOGIN_STATUS} from './loginTypes'
const storedAuth = localStorage.getItem('auth')
let auth: IAuth = storedAuth ? JSON.parse(storedAuth) : null

const defaultState: ILoginState = {auth: auth, status: undefined, isLoading: false, errorMessage: '', aadErrorMessage: '', aadStatus: undefined}

const loginReducer: Reducer<ILoginState> = (state = defaultState, action) => {
  switch (action.type) {
    case LOGGING_IN:
      return {...state, auth: null, status: undefined, isLoading: true}
    case LOGIN_SUCCEDED:
      return {
        ...state,
        auth: action.payload.auth,
        status: action.payload.status,
        isLoading: false,
      }
    case LOGIN_UNAUTHORIZED:
      return {...state, auth: null, status: action.payload.status, isLoading: false}
    case LOGIN_FAILED:
      const payload = action.payload.response ? action.payload.response.status : -1
      return {...state, auth: null, status: payload, isLoading: false}
    case LOGOUT:
      return {...state, auth: null, status: undefined, isLoading: false}
    case SET_AAD_LOGIN_STATUS:
      return {...state, aadStatus: action.payload.status, aadErrorMessage: action.payload.errorMessage }
    default:
      return state
  }
}
export default loginReducer
