import {useIsAuthenticated, useMsal} from '@azure/msal-react'
import React from 'react'
import {useDispatch} from 'react-redux'
import {useNavigate} from 'react-router'
import {logout} from '../../redux/login/loginActions'
const NoAccess = () => {
  const {instance} = useMsal()
  const isAuthenticated = useIsAuthenticated()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const handleOnLogOutClick = () => {
    if (isAuthenticated) {
      instance.logout()
      dispatch(logout)
      navigate('/login')
    } else {
      dispatch(logout)
      navigate('/login')
    }
  }

  return (
    <div>
      <h1>No access</h1>
      <p>
        You do not have access to this page. Would you like to{' '}
        <a href='#' onClick={handleOnLogOutClick}>
          log out
        </a>
        ?
      </p>
    </div>
  )
}
export default NoAccess
