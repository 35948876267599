import {CustomReportParameter, CustomReportValueParameter, IReportDefinition, IReportDefinitionParameter, ReportPreview} from '../redux/report/interfaces'
import saveupClient, {handleError, handleResponse} from '../clients/saveupClient'
import { applyTransformations, generateCsvFile, isNullOrWhiteSpace, jsonToCsv } from '../shared/utils/utilities'
import { M_EXP_2 } from '../shared/utils/constants'

const defaultParameters: IReportDefinitionParameter[] = [
  {name: 'fromYear', title: 'Från år', value: new Date().getFullYear().toString()},
  {name: 'fromMonth', title: 'Från period', value: new Date().getFullYear().toString()},
  {name: 'toYear', title: 'Till år', value: new Date().getFullYear().toString()},
  {name: 'toMonth', title: 'Till period', value: new Date().getFullYear().toString()},
]

async function getReports() {
  const reports: IReportDefinition[] = [
    {
      id: 1,
      name: 'COUNTERS_WITHOUT_READING',
      title: 'Ej avlästa mätare',
      description: 'Rapporten tar ut en lista över samtliga räkneverk som ej lästs av under den valda tidsperioden. Rapporten undantar objekt som är satta som ”avläses ej”.',
      columnHeaders: ['Fastighetsägare', 'Fastighet', 'Adress', 'Mätpunkt', 'Mätare', 'Mätarnummer', 'Typ', 'Enhet', 'Kontor', 'MätarId'],
      parameters: defaultParameters,
    },

    {
      id: 2,
      name: 'COUNTERS_WITH_DECREASING_READING',
      title: 'Mätare med negativ förbrukning inom period',
      description: 'Rapporten tar ut samtliga mätare som har en förbrukning som är negativ under den valda tidsperioden. Eftersom en förbrukning inte kan vara negativ om allt står rätt till så kan denna användas för att hitta alla mätare som byts ut eller slagit runt och ännu inte hanterats.',
      columnHeaders: ['Fastighetsägare', 'Fastighet', 'Adress', 'Mätpunkt', 'Mätare', 'Mätarnummer', 'Typ', 'Enhet', 'Kontor', 'MätarId', 'Avvikelsekategori'],
      parameters: defaultParameters,
      propertyTransforms: [ { propertyIndex: 10, doTransform: (value: any) => isNullOrWhiteSpace(value) ? '' : value === "RESET" ? 'Mätarbyte / Rundslagning' : 'Avvikelse' } ]
    },

    {
      id: 3,
      name: 'CONSUMPTION_ATEMP_LOA_DEVIATIONS',
      title: 'Avvikelse gentemot föregående år (OBS: Tar lång tid)',
      description: 'Rapporten tar ut en lista över samtliga räkneverk och listar hur mycket förbrukningen (under den valda perioden) avviker från samma månad det föregående året.',
      columnHeaders: [
        'Fastighetsägare',
        'Fastighet',
        'Adress',
        'Mätpunkt',
        'Mätare',
        'Mätarnummer',
        'Typ',
        'Enhet',
        'Kontor',
        'År',
        'Månad',
        'Avläsning',
        'Föregående års förbrukning',
        'Förbrukning',
        "Föregående års ATemp",
        "ATemp",
        "Föregående års LOA",
        "LOA",
        'Har avvikelse',
        'Avvikelse förbrukning',
        'Avvikelse ATemp',
        'Avvikelse LOA',        
        'Tillåten avvikelse',
        'Graddagsjusterad',
        'MätarId',
      ],
      parameters: defaultParameters,
    },

    {
      id: 4,
      name: 'DEVIATIONS',
      title: 'Tillåtna avvikelsevärden',
      description: 'Rapporten tar ut en lista över samtliga räkneverk och listar vilka gränsvärden för avvikelser de är inställda på.',
      columnHeaders: ['Tillåten avikelse', 'Fastighetsägare', 'Fastighet', 'Adress', 'Mätpunkt', 'Mätare', 'Mätarnummer', 'Typ', 'Enhet', 'Kontor', 'MätarId'],
      parameters: defaultParameters,
    },

    {
      id: 5,
      name: 'DEVIATIONS_CASE_OPEN',
      title: 'Pågående avvikelseärenden',
      description: 'Rapporten skapar en lista över samtliga avvikelse-ärenden för den valda perioden som inte är avslutade.',
      columnHeaders: ['Fastighetsägare', 'Fastighet', 'Adress', 'Mätpunkt', 'Mätare', 'Mätarnummer', 'Typ', 'Enhet', 'Kontor', 'Datum', 'Ärende', 'Beskrivning', 'MätarId'],
      parameters: defaultParameters,
    },

    {
      id: 6,
      name: 'MESTRO_COUNTERS',
      title: 'Externt kopplade mätare',
      description: 'Rapporten listar samtliga räkneverk som kopplats mot ett externt system, om de är inställda för att exportera eller importera värden, vilket system de är kopplade mot, samt vilket ID/nyckel som angivits för integrationen.',
      columnHeaders: ['Fastighetsägare', 'Fastighet', 'Adress', 'Mätpunkt', 'Mätare', 'Mätarnummer', 'Typ', 'Enhet', 'Kontor', 'Integrationstyp', 'System', 'Externt Id', 'Mestro Nod-Id', 'MätarId'],
      parameters: defaultParameters,
    },
    {
      id: 7,
      name: 'COUNTERS_ALL',
      title: 'Alla mätare',
      description: 'Rapporten listar upp samtliga räkneverk på valt kontor (eller alla kontor) och anger om de är inställda för att läsas av eller inte, samt vilket kontor, kund, fastighet etc. de hör till.',
      columnHeaders: ['Fastighetsägare', 'Fastighet', 'Adress', 'Mätpunkt', 'Mätare', 'Mätarnummer', 'Typ', 'Enhet', 'Kontor', 'Avläses', 'Inaktiva perioder', 'MätarId'],
      parameters: defaultParameters,
    },
    {
      id: 8,
      name: 'HEATEXCHANGER_EFFICIENCY',
      title: 'Värmeväxlare och effektivitetsgrad',
      description: 'Rapporten listar samtliga för perioden avlästa värmeväxlare tillsammans med uträknad effektivitetsgrad, gränsvärden för lägsta effektivitetsgrad, om avläsningen har någon avvikelse och tillhörande kommentar.',
      columnHeaders: [
        'Fastighetsägare',
        'Fastighet',
        'Adress',
        'Mätpunkt',
        'Värmeväxlare',
        'Nummer',
        'Tidpunkt',
        'Typ',
        'Effektivitetsgrad (%)',
        'Gränsvärde (%)',
        'Har avvikelse',
        'Meddelande',
        'Status',
        'Beskrivning',
        'Extra kommentar',
        'DB-ID',
      ],
      parameters: defaultParameters,
    },
    {
      id: 9,
      name: 'ESTATE_AREAS_CONSUMPTION',
      title: 'Årsförbrukningar och fastighetsytor',
      description: 'Rapporten listar samtliga fastigheter, deras fastighetskategorier, deras ytor (LOA och Atemp) samt totala förbrukning på helårsbasis per förbrukningsslag,  per m2 LOA och Atemp. Vid ofullständiga år så visas också ett estimat på förväntat helårsförbrukning per m2 LOA och Atemp. Motsvarande rapport går även att ta ut för en enstaka fastighetsägare eller fastighet, den heter då ”CSV -Fastighetsytor” och beskrivs under kapitlet Rapporter för valda ägare, fastigheter eller noder.',
      columnHeaders: [
        'Fastighetsägare',
        'Fastighet',
        'Fastighetskategori',
        'Mätartyp',
        'År',
        'Aktuell årsförbrukning',
        'Estimerad årsförbrukning',
        'Enhet',
        `LOA ${M_EXP_2}`,
        'LOA Aktuell',
        'LOA Estimerad',
        'LOA Enhet',
        `ATemp ${M_EXP_2}`,
        'ATemp Aktuell',
        'ATemp Estimerad',
        'ATemp enhet'
      ],
      parameters: defaultParameters,
      segmented: true,
      type: 'estates'
    },
  ]
  return reports
}

async function runReport(reportParameter: CustomReportParameter) {    
  return await saveupClient.post('report', reportParameter, {timeout: 360000}).then(response => {  
    return response?.data
  })
}

async function getPreview(reportParameter: CustomReportParameter) {
  return await saveupClient.post<ReportPreview>('report/preview', reportParameter).then(response => {
    return response?.data
  })
}

const saveReportAsCsv = (report: IReportDefinition, results: any[], startDate: Date, stopDate: Date) => {
  const fileName = generateFileName(report.title, startDate, stopDate)
  const csvContent = jsonToCsv(applyTransformations(results, report.propertyTransforms), report.columnHeaders)
  generateCsvFile(csvContent, fileName)
}

const generateFileName = (title: string, startDate: Date, stopDate: Date) => {
  const datePortion = `${startDate.getFullYear()}-${startDate.getMonth() + 1}_${stopDate.getFullYear()}-${stopDate.getMonth() + 1}`
  const timePortion = new Date().toLocaleString().substring(0, 10)
  return `${title.toLowerCase()} ${datePortion}_${timePortion}.csv`
}

const processReportInBatches = async (objectIds: number[], customReportParameter: CustomReportParameter) => {
  const batchSize = 20
  let results : any[] = []

  for (let i = 0; i < objectIds.length; i += batchSize) {
    const slice = objectIds.slice(i, i + batchSize)
    const valueParameters: CustomReportValueParameter[] = slice.map((id) => ({name: 'estate', value: id.toString()}))
    const parameter: CustomReportParameter = {...customReportParameter, parameters: [...customReportParameter.parameters, ...valueParameters]}      
   
    const sliceResult = await reportService.runReport(parameter)
    results.push(...sliceResult)      
  }
  return results
}

const generateBatchReports = async (report: IReportDefinition, preview: ReportPreview, customReportParameter: CustomReportParameter, startDate: Date, stopDate: Date) => {
  const results = await processReportInBatches(preview.uniqueObjectIds, customReportParameter)
  if (results.length > 0) {
    saveReportAsCsv(report, results, startDate, stopDate)
  }
}


const reportService = {
    getReports,
    runReport,
    getPreview,
    saveReportAsCsv,
    generateBatchReports
}
export default reportService