import React from 'react'
import {Outlet, Navigate} from 'react-router-dom'
import {isAdmin, isAuthorized, isTokenExpired} from '../../shared/utils/utilities'
import {IAuth} from '../../redux/login/loginInterfaces'

const AdminRoutes = () => {
  const auth: IAuth = localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth') as string) : null
  return (auth.isAAD || !isTokenExpired()) && isAdmin() ? <Outlet /> : <Navigate to='/home' />
}
export default AdminRoutes
