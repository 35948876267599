import React, {useEffect, useState} from 'react'
import {DisableUserState, IUser, UserListItem, UserListState, UserRole, UserRoleState} from '../../redux/user/interfaces'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../redux/rootReducer'
import UserList from './UserList'
import {updateUserState, getUserRoles, getUsers, getUserListItems} from '../../redux/user/actions'
import User from './Users'
const UserContainer = () => {
  const dispatch = useDispatch()
  // Get userlist from redux .
  const userListState: UserListState = useSelector((state: RootState) => state.user.userList)
  const userRoles: UserRoleState = useSelector((state: RootState) => state.user.userRoles)
  const disabledUser: IUser | undefined = useSelector((state: RootState) => state.user.disableUser?.user)

  const [userListItems, setUserListItems] = useState<UserListItem[]>([])
  const [disabledUserListItems, setDisabledUserListItems] = useState<UserListItem[]>([])

  useEffect(() => {
    dispatch(getUserListItems())
    dispatch(getUserRoles())
  }, [])

  useEffect(() => {
    const combinedUserListItems: UserListItem[] = userListState.users.map((user) => {
      const userRole = userRoles.userRoles.find((role: UserRole) => role.id === user.userRoleId)
      return {...user, role: userRole?.name ?? '', office: '', owners: [], canEdit: false, canReadDeviations: false}
    })
    const sortedListItems = combinedUserListItems.filter((x) => !x.isDisabled).sort((a, b) => a.fullName.trim().toLowerCase().localeCompare(b.fullName.trim().toLowerCase()))
    const disabledSortedListItems = combinedUserListItems.filter((x) => x.isDisabled).sort((a, b) => a.fullName.trim().toLowerCase().localeCompare(b.fullName.trim().toLowerCase()))
    setUserListItems(sortedListItems)
    setDisabledUserListItems(disabledSortedListItems)
  }, [userListState, userRoles])

  useEffect(() => {
    if (disabledUser) {
      dispatch(getUsers())
    }
  }, [disabledUser])

  const handleDisableUser = (id: number, isDisabled: boolean) => {
    dispatch(updateUserState(id, isDisabled))
    if (isDisabled) {
      const disabledUserItem = userListItems.find((x) => x.id === id)
      setUserListItems((prev) => prev.filter((x) => x.id !== id))
      if (disabledUserItem) setDisabledUserListItems((prev) => [...prev, disabledUserItem])
    } else {
      const enabledUserListItem = userListItems.find((x) => x.id === id)
      setDisabledUserListItems((prev) => prev.filter((x) => x.id !== id))
      if (enabledUserListItem) setUserListItems((prev) => [...prev, enabledUserListItem])
    }
  }

  return (
    <div>
      <User users={userListItems} disabledUsers={disabledUserListItems} isLoading={userListState.isLoading || userRoles.isLoading} disableUser={handleDisableUser} />
    </div>
  )
}
export default UserContainer
