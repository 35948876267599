import {useContext} from 'react'
import {AutoFixHigh, Menu as MenuIcon} from '@mui/icons-material'
import {Box, Hidden, Tooltip} from '@mui/material'
import {SidebarContext} from '../../../../components/shared/SidebarContext'
import HeaderUserbox from './Userbox'
import {HeaderWrapper, IconButtonStyle} from './HeaderStyles'
import Logo from './Logo'
import { Role } from '../../../../shared/enums/Role'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux/rootReducer'
import { IAuth } from '../../../../redux/login/loginInterfaces'

function Header() {
  const {sidebarToggle, toggleSidebar} = useContext(SidebarContext)

  const auth: IAuth = JSON.parse(localStorage.getItem('auth') ?? "")

  return (
    <HeaderWrapper display='flex' alignItems='center'>
      <Box display='flex' alignItems='center'>
        <Hidden lgUp={true}>
          <Tooltip arrow={true} title='Toggle Menu'>
            <IconButtonStyle color='primary' onClick={toggleSidebar}>
              {!sidebarToggle ? <MenuIcon /> : <MenuIcon />}
            </IconButtonStyle>
          </Tooltip>
        </Hidden>
        <Logo />
      </Box>
      <Box display='flex' alignItems='center'>
        <HeaderUserbox userName={auth.userName} role={auth.role} />
      </Box>
    </HeaderWrapper>
  )
}

export default Header
