import LabeledSelect from '../../shared/Inputs/LabeledSelect'
import SecButton from '../../shared/Inputs/SecButton'
import SecSelect from '../../shared/Inputs/SecSelect'
import SecTextField from '../../shared/Inputs/SecTextField'
import {Close, CoPresentOutlined, Delete, Edit, ResetTv, Save} from '@mui/icons-material'
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
} from '@mui/material'
import {getAllArea} from '../../../redux/area/actions'
import {deleteEstate, getEstate, getEstateAttributeControls, updateEstate} from '../../../redux/estate/actions'
import {getOwnerById} from '../../../redux/owner/ownerActions'
import {RootState} from '../../../redux/rootReducer'
import {SaveResponse} from '../../../shared/enums/SaveResponse'
import { hasPermissions, isAdmin, isNullOrWhiteSpace, sortSelectItems} from '../../../shared/utils/utilities'
import React, {useEffect, useState} from 'react'
import {Controller, useForm, useFormContext} from 'react-hook-form'
import {useDispatch, useSelector} from 'react-redux'
import {Link, useNavigate } from 'react-router-dom'
import AddressList from '../addresses/AddressList'
import ReaderView from './reader/ReaderView'
import SaveAlert from '../../shared/alerts/SaveAlert'
import SearchSelectDialog, {ISelectItem} from '../../shared/dialogs/SearchSelectDialog'
import ownerService from '../../../services/ownerService'
import {IOwner} from '../../../redux/owner/ownerInterfaces'
import {IEstateDetailsProps, IUpdateEstateForm} from './interfaces'
import {NODE_WRITE} from '../../../shared/utils/constants'
import FormAttributeControlPanel from '../../attributes/controls/FormAttributeControlPanel'

const EstateDetails = (props: IEstateDetailsProps) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [saveResult, setSaveResult] = useState(SaveResponse.NOT_SET)
  const [alertOpen, setAlertOpen] = useState(false)

  const estate = useSelector((state: RootState) => state.estate.selectedEstate)
  const areas = useSelector((state: RootState) => state.area.areas)
  const offices = useSelector((state: RootState) => state.filter.offices)
  const owner = useSelector((state: RootState) => state.owner.selectedOwner)
  const controls = useSelector((state: RootState) => state.estate.estateAttributeControls.controls)
  const [deleteEstateDialogOpen, setDeleteEstateDialogOpen] = useState<boolean>(false)
  const [selectOwnerDialogOpen, setSelectOwnerDialogOpen] = useState<boolean>(false)
  const [hasAdminRights, setHasAdminRights] = useState<boolean>(false)
  const [canEdit, setCanEdit] = useState<boolean>(false)

  const methods = useForm<IUpdateEstateForm>({
    defaultValues: {
      ownerId: estate?.ownerId ?? 0,
      name: estate?.name ?? '',
      rekylId: estate?.rekylId ?? '',
      atemp: estate?.atemp ?? '',
      loa: estate?.loa ?? '',
      office: estate && !isNullOrWhiteSpace(estate.office) ? estate.office : 'NOT_SET',
      category: estate && !isNullOrWhiteSpace(estate.category) ? estate.category : 'NOT_SET',
      description: estate?.description ?? '',
      area: estate?.areaId ?? 0,
      hidden: estate?.hidden ?? false,
    },
  })

  useEffect(() => {
    dispatch(getEstate(props.estateId))
    dispatch(getAllArea())
    dispatch(getEstateAttributeControls())
  }, [props.estateId])

  useEffect(() => {
    setCanEdit(hasPermissions([NODE_WRITE]))
    setHasAdminRights(isAdmin())
    dispatch(getAllArea())
  }, [])

  useEffect(() => {
    if (estate) {
      dispatch(getOwnerById(estate.ownerId))
      methods.reset({
        ownerId: estate?.ownerId ?? 0,
        name: estate?.name ?? '',
        rekylId: estate?.rekylId ?? '',
        atemp: estate?.atemp ?? '',
        loa: estate?.loa ?? '',
        office: estate && !isNullOrWhiteSpace(estate.office) ? estate.office : 'NOT_SET',
        category: estate && !isNullOrWhiteSpace(estate.category) ? estate.category : 'NOT_SET',
        description: estate?.description ?? '',
        area: estate?.areaId ?? 0,
        hidden: estate?.hidden ?? false,
      })
    }
  }, [estate])

  const [area, setArea] = useState('')
  const [office, setOffice] = useState('')
  const [isHidden, setIsHidden] = useState(true)

  const onSubmit = (data: IUpdateEstateForm) => {
    if (!estate) {
      alert('Denna fastighet saknar data och kan inte redigeras i denna vy.')
      return
    }
    let ownerId = owner && owner.owner ? owner.owner.id : estate.ownerId
    if (estate && estate.id === props.estateId) dispatch(updateEstate(estate?.id, ownerId, data.name, data.description, data.hidden, data.area, data.rekylId, data.office, data.atemp, data.loa, data.category))
    setSaveResult(SaveResponse.SUCCESS)
    setAlertOpen(true)
  }
  const onChangeOfficeClick = (e: any) => alert('Opening office add modal')

  const onNewAddressClick = (e: any) => alert('Skapar ny adress')

  const onAreaChange = (e: any) => setArea(e.target.value)
  const onOfficeChange = (e: any) => setOffice(e.target.value)

  const estateName = estate ? ' ' + estate.name : ''

  const onDeleteEstateClick = (e: any) => {
    if (estate) {
      setDeleteEstateDialogOpen(false)
      dispatch(deleteEstate(estate.id))
      navigate(`/owner/${estate.ownerId}`)
    }
  }
  const onChangeOwnerClick = (e: any) => setSelectOwnerDialogOpen(true)
  const onChangeOwnerConfirmClick = (value: any) => {
    setSelectOwnerDialogOpen(false)
    if (value) {
      dispatch(getOwnerById(value))
    }
  }
  const onOwnerSearchPerformed = async (keyword: string) => {
    let result: ISelectItem[] = []
    if (keyword && keyword.trim().length >= 3) {
      let estates = await ownerService.getOwnersByName(keyword)
      result = estates.map((x: IOwner) => {
        const item: ISelectItem = {id: x.id, text: x.name}
        return item
      })
      result = sortSelectItems(result)
    }
    return result
  }

  const renderEditButton = () =>
    canEdit && (
      <Grid item xs={10}>
        <SecButton startIcon={<Save />} variant='contained' onClick={methods.handleSubmit(onSubmit)}>
          Spara
        </SecButton>
      </Grid>
    )

  const renderDeleteButton = () =>
    hasAdminRights && (
      <Grid item xs={2}>
        <SecButton startIcon={<Delete />} variant='contained' onClick={(e: React.MouseEvent<HTMLButtonElement>) => setDeleteEstateDialogOpen(true)} color='error' sx={{float: 'right'}}>
          Radera
        </SecButton>
      </Grid>
    )

  const renderModifyButtons = () => (
    <Grid item xs={12} container justifyContent='flex-start' direction='row' spacing={5}>
      {renderEditButton()}
      {renderDeleteButton()}
    </Grid>
  )

  return (
    <div>
      <Container maxWidth='lg'>
        <Grid container direction='row' justifyContent='center' alignItems='stretch' spacing={3}>
          <Grid item xs={12}>
            <Card component='form' noValidate autoComplete='off'>
              <CardHeader title={`Redigera fastighet${estateName}`} />
              <Divider />
              <CardContent>
                <Grid container spacing={5}>
                  <Grid item xs={12} container justifyContent='flex-start' direction='row' spacing={2}>
                    <Grid item xs={12}>
                      <SaveAlert
                        result={saveResult}
                        open={alertOpen}
                        onClose={() => setAlertOpen(false)}
                        successMessage='Fastigheten har sparats.'
                        errorMessage='Misslyckades med att spara fastigheten.'
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} container justifyContent='flex-start' direction='row' spacing={5}>
                    <Grid item xs={3}>
                      <SecTextField value={props.estateId} fullWidth inputProps={{maxLength: 40}} disabled={true} InputProps={{readOnly: true}} label='ID' />
                    </Grid>
                    <Grid item xs={2}>
                      <Controller
                        name='name'
                        control={methods.control}
                        render={({field}) => <SecTextField {...field} fullWidth inputProps={{maxLength: 40}} InputProps={{readOnly: !canEdit}} label='Namn' />}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Controller
                        name='rekylId'
                        control={methods.control}
                        render={({field}) => <SecTextField {...field} fullWidth inputProps={{maxLength: 40}} InputProps={{readOnly: !canEdit}} label='Rekyl-ID' />}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Controller
                        name='atemp'
                        control={methods.control}
                        render={({field}) => <SecTextField {...field} fullWidth inputProps={{maxLength: 40}} InputProps={{readOnly: !canEdit}} label='Atemp (m²)' />}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Controller
                        name='loa'
                        control={methods.control}
                        render={({field}) => <SecTextField {...field} fullWidth inputProps={{maxLength: 40}} InputProps={{readOnly: !canEdit}} label='LOA (m²)' />}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} container justifyContent='flex-start' direction='row' spacing={5}>
                    <Grid item xs={7}>
                      <Controller
                        name='description'
                        control={methods.control}
                        render={({field}) => <SecTextField {...field} fullWidth inputProps={{maxLength: 40}} InputProps={{readOnly: !canEdit}} label='Beskrivning' />}
                      />
                    </Grid>
                  </Grid>
                  <FormAttributeControlPanel useFormReturn={methods} attributeControls={controls}/>
                  <Grid item xs={12} container justifyContent='flex-start' direction='row' spacing={5}>
                    {hasAdminRights && (
                      <Grid item xs={3}>
                        <SecTextField
                          value={owner?.owner ? owner.owner.name : ''}
                          fullWidth
                          inputProps={{maxLength: 40}}
                          disabled={true}
                          label='Fastighetsägare'
                          InputProps={{
                            readOnly: !canEdit,
                            endAdornment: (
                              <SecButton startIcon={<Edit />} onClick={onChangeOwnerClick}>
                                Ändra
                              </SecButton>
                            ),
                          }}
                        />
                      </Grid>
                    )}
                    <Grid item xs={3}>
                      <FormControl variant='outlined' sx={{minWidth: 120}} fullWidth>
                        <InputLabel id={`areaLabel`} variant='outlined' htmlFor={`area`} shrink={true}>
                          Graddagsort:
                        </InputLabel>
                        <Controller
                          name='area'
                          control={methods.control}
                          render={({field}) => (
                            <Select fullWidth {...field} notched={true} id='area' label='Graddagsort' disabled={!canEdit}>
                              <MenuItem value={0}>-- Välj graddagsort ---</MenuItem>
                              {areas.map((area, index) => (
                                <MenuItem key={index} value={area.id}>
                                  {area.name}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                      <FormControl variant='outlined' sx={{minWidth: 120}} fullWidth>
                        <InputLabel id={`officeLabel`} variant='outlined' htmlFor={`office`} shrink={true}>
                          Kontor:
                        </InputLabel>
                        <Controller
                          name='office'
                          control={methods.control}
                          render={({field}) => (
                            <Select fullWidth {...field} notched={true} id='office' label='Kontor' disabled={!canEdit}>
                              <MenuItem value='NOT_SET'>--- Välj kontor ---</MenuItem>
                              {offices.map((office, index) => (
                                <MenuItem key={index} value={office.name}>
                                  {office.name}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                      <FormControlLabel
                        sx={{marginTop: '10px'}}
                        control={
                          <Controller
                            name='hidden'
                            control={methods.control}
                            render={({field}) => {
                              return <Switch checked={field.value} onChange={(e) => field.onChange(e.target.checked)} title='Avläses ej' disabled={!canEdit} />
                            }}
                          />
                        }
                        label='Avläses ej'
                      />
                    </Grid>
                  </Grid>
                  {renderModifyButtons()}
                </Grid>
              </CardContent>
            </Card>
            {hasAdminRights ? (
              <Card component='form' noValidate autoComplete='off' sx={{marginTop: '20px'}}>
                <CardHeader title='Avläsare' />
                <Divider />
                <CardContent>
                  <ReaderView estateId={props.estateId} />
                </CardContent>
              </Card>
            ) : (
              ''
            )}
            <Card component='form' noValidate autoComplete='off' sx={{marginTop: '20px'}}>
              <CardHeader title='Adresser' />
              <Divider />
              <CardContent>
                <Grid item xs={12} container justifyContent='flex-start' direction='row' spacing={5}>
                  <Grid item xs={12}>
                    <AddressList estateId={props.estateId} />
                  </Grid>
                  {hasAdminRights && (
                    <Grid item xs={12}>
                      <Link to={`/estate/${props.estateId}/address/create`}>
                        <SecButton startIcon={<Save />} variant='contained'>
                          Ny adress
                        </SecButton>
                      </Link>
                    </Grid>
                  )}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {hasAdminRights && (
          <div>
            <Dialog open={deleteEstateDialogOpen} onClose={() => setDeleteEstateDialogOpen(false)} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
              <DialogTitle id='alert-dialog-title'>{'Radera fastighet'}</DialogTitle>
              <DialogContent>
                <DialogContentText id='alert-dialog-description'>
                  Vill du radera fastigheten {isNullOrWhiteSpace(estate?.name) ? `${estate?.name}(${estate?.id})` : `med ID: ${estate?.id}`}?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={onDeleteEstateClick} autoFocus>
                  Radera
                </Button>
                <Button onClick={() => setDeleteEstateDialogOpen(false)}>Avbryt</Button>
              </DialogActions>
            </Dialog>
          </div>
        )}
        <SearchSelectDialog
          open={selectOwnerDialogOpen}
          title='Flytta fastighet'
          defaultItemLabel='-- Välj fastighetsägare --'
          text='Välj den fastighetsägare till vilken du vill flytta fastigheten.'
          onConfirm={onChangeOwnerConfirmClick}
          onCancel={() => setSelectOwnerDialogOpen(false)}
          onSearch={onOwnerSearchPerformed}
        />
      </Container>
    </div>
  )
}
export default EstateDetails
