import {Add, Check, CheckBox, Close, Delete, Help, Remove, RemoveCircle, RotateRight, Rowing, SyncAlt} from '@mui/icons-material'
import {Alert, Button, Card, CardContent, Collapse, Grid, IconButton, styled, TableRow, Typography} from '@mui/material'
import {grey} from '@mui/material/colors'
import {DataGrid, GridActionsCellItem, GridActionsColDef, GridColDef, GridRenderCellParams, GridRowParams} from '@mui/x-data-grid'
import {getCounterReadings, getIsCounterMultiValue} from '../../../redux/counter/actions'
import {ICounterReadingProps, ICounterReset, ICounterValue, ICounterValueListItem, ICounterValueLog, ISubValue} from '../../../redux/counter/interfaces'
import {counterReducer} from '../../../redux/counter/reducer'
import {deleteCounterValue, saveCounterValue, saveCounterValues, updateCounterValue, updateCounterValues} from '../../../redux/counterValue/actions'
import {ICounterValueForm, ICounterValueInputDialogProps, ICounterValuesForm, ICounterValuesInputDialogProps, IValues} from '../../../redux/counterValue/interfaces'
import {RootState} from '../../../redux/rootReducer'
import {hasEditPermissions, hasPermissions, htmlDecode, isNullOrWhiteSpace} from '../../../shared/utils/utilities'
import React, {MouseEventHandler, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import CounterValueInputDialog from './value/CounterValueInputDialog'
import CounterValuesInputDialog from './value/CounterValuesInputDialog'
import {NODE_WRITE} from '../../../shared/utils/constants'

enum SaveResponse {
  SUCCESS,
  FAIL,
  PENDING,
  NOT_SET,
}

const StripedDataGrid = styled(DataGrid)(({theme}) => ({
  '& .MuiDataGrid-row:nth-of-type(odd)': {
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
  },
}))

const CounterReadings = (props: ICounterReadingProps) => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [selectedRow, setSelectedRow] = useState<ICounterValueListItem | null>(null)
  const [saveResult, setSaveResult] = useState(SaveResponse.NOT_SET)
  const [alertOpen, setAlertOpen] = useState(false)
  const [canEdit, setCanEdit] = useState<boolean>(false)

  let readings = useSelector((state: RootState) => state.counter.readings)
  readings?.forEach((x) => {
    x.reader = isNullOrWhiteSpace(x.createdByUserName) ? x.userName : x.createdByUserName
  })
  const savedCounterValue = useSelector((state: RootState) => state.counter.savedReading)

  useEffect(() => {
    setCanEdit(hasPermissions([NODE_WRITE]))
  }, [])

  useEffect(() => {
    setSaveResult(SaveResponse.NOT_SET)
    dispatch(getCounterReadings(props.counter.id))
    dispatch(getIsCounterMultiValue(props.counter.id))
  }, [props.counter.id])

  const isMultiValue = useSelector((state: RootState) => state.counter.isMultiValue)

  useEffect(() => {
    dispatch(getCounterReadings(props.counter.id))
    if (savedCounterValue && saveResult === SaveResponse.PENDING) {
      setSaveResult(SaveResponse.SUCCESS)
      setAlertOpen(true)
    } else if (saveResult === SaveResponse.PENDING) {
      setSaveResult(SaveResponse.FAIL)
      setAlertOpen(true)
    }
  }, [savedCounterValue])

  useEffect(() => {
    if (selectedRow) {
      setOpen(true)
    }
  }, [selectedRow])

  const deleteColumn: GridActionsColDef = {
    field: 'actions',
    type: 'actions',
    width: 100,
    cellClassName: 'actions',
    getActions: (params: GridRowParams) => {
      return canEdit
        ? [
            <GridActionsCellItem
              icon={<Delete />}
              label='Radera'
              onClick={() => handleDeleteClick(params.row)}
              color='inherit'              
              onResize={() => {}}
              onResizeCapture={() => {}}
              showInMenu={true}
            />,
          ]
        : []
    },
  }

  const getResetIcon = (item: any) => {
    if (item.counterResets?.Count === 0) return ''

    const reset: ICounterReset = item.counterResets[0]
    if (reset.turned) return <RotateRight sx={{fontSize: 'small', marginTop: '4px'}} />
    else if (reset.changed) return <SyncAlt sx={{fontSize: 'small', marginTop: '4px'}} />
    else return <Help sx={{fontSize: 'small', marginTop: '4px'}} />
  }

  const columns: GridColDef[] = canEdit
    ? [
        {field: 'id', headerName: 'Id', flex: 1},
        {field: 'reader', headerName: 'Avläsare', flex: 1},
        {field: 'value', headerName: 'Värde', flex: 1},
        {field: 'estimated', headerName: 'Beräknat', flex: 1},
        {field: 'reading', headerName: 'Är avläst', flex: 1},
        {field: 'date', headerName: 'Tidpunkt', flex: 1},
        {field: 'insDate', headerName: 'Inmatades', flex: 1},
        {field: 'updDate', headerName: 'Ändrades', flex: 1},
        {field: 'userName', headerName: 'Ändrades av', flex: 1},
        {
          field: 'counterValueLogs',
          headerName: 'Meddelande',
          flex: 1,
          minWidth: 200,
          renderCell: (params: GridRenderCellParams<ICounterValueLog[]>) => {
            const logMessage = params.value && params.value.length > 0 ? htmlDecode(params.value[0].text) : ''
            const messageElement = <Typography>{logMessage}</Typography>
            return params.row.hasReset ? (
              <div style={{display: 'flex'}}>
                {getResetIcon(params.row)}&nbsp;{messageElement}
              </div>
            ) : (
              messageElement
            )
          },
        },
        deleteColumn,
      ]
    : [
        {field: 'id', headerName: 'Id', flex: 1},
        {field: 'reader', headerName: 'Avläsare', flex: 1},
        {field: 'value', headerName: 'Värde', flex: 1},
        {field: 'estimated', headerName: 'Beräknat', flex: 1},
        {field: 'reading', headerName: 'Är avläst', flex: 1},
        {field: 'date', headerName: 'Tidpunkt', flex: 1},
        {field: 'insDate', headerName: 'Inmatades', flex: 1},
        {field: 'updDate', headerName: 'Ändrades', flex: 1},
        {field: 'userName', headerName: 'Ändrades av', flex: 1},
        {field: 'log', headerName: 'Log', flex: 1},
      ]

  const handleOnClick = (row: ICounterValueListItem) => {
    // if (!canEdit) return
    setSelectedRow(row)
  }

  const onValueConfirmClick = (data: ICounterValueForm) => {
    if (!canEdit) return
    if (data.counterValueId && data.counterValueId > 0) {
      dispatch(updateCounterValue(data.counterValueId, data.value))
    } else {
      dispatch(saveCounterValue(props.counter.id, data.value, data.date))
    }
    setOpen(false)
    setSaveResult(SaveResponse.PENDING)
  }
  const onValuesConfirmClick = (data: ICounterValuesForm) => {
    if (!canEdit) return
    if (data.counterValueId && data.counterValueId > 0) {
      dispatch(updateCounterValues(data.counterValueId, data.fromAirValue, data.offAirValue, data.outAirValue))
    } else {
      dispatch(saveCounterValues(props.counter.id, data.fromAirValue, data.offAirValue, data.outAirValue, data.date))
    }
    setOpen(false)
    setSaveResult(SaveResponse.PENDING)
  }

  const handleSaveNewReadingClick = (e: any) => {
    setSelectedRow(null)
    setOpen(true)
  }

  const handleDeleteClick = (row: any) => {
    if (!canEdit) return
    dispatch(deleteCounterValue(row.id))
  }

  const renderReadings = () => {
    const readingsWithKey = readings ? readings.map((reading: ICounterValueListItem) => ({...reading, key: reading.id})) : []
    return (
      <Card>
        <CardContent>
          <StripedDataGrid rows={readingsWithKey} columns={columns} autoHeight onRowClick={(param: any) => handleOnClick(param.row)}></StripedDataGrid>
        </CardContent>
      </Card>
    )
  }

  const renderAlert = () => {
    let alert = <div></div>
    switch (saveResult) {
      case SaveResponse.SUCCESS:
        alert = (
          <Alert
            severity='success'
            action={
              <IconButton
                aria-label='close'
                color='inherit'
                size='small'
                onClick={() => {
                  setAlertOpen(false)
                }}>
                <Close fontSize='inherit' />
              </IconButton>
            }
            sx={{mb: 2}}>
            Värdet {savedCounterValue?.value} sparades för mätaren {savedCounterValue?.counterId}
          </Alert>
        )
        break
      case SaveResponse.FAIL:
        alert = (
          <Alert
            severity='error'
            action={
              <IconButton
                aria-label='close'
                color='inherit'
                size='small'
                onClick={() => {
                  setAlertOpen(false)
                }}>
                <Close fontSize='inherit' />
              </IconButton>
            }
            sx={{mb: 2}}>
            Misslyckades med att spara avläsningen!
          </Alert>
        )
        break
      default:
        break
    }
    return <Collapse in={alertOpen}>{alert}</Collapse>
  }

  const renderInputDialog = () => {
    if (isMultiValue) {
      return <CounterValuesInputDialog open={open} counterId={props.counter.id} counterValueListItem={selectedRow} onClose={() => setOpen(false)} onConfirm={(e: any) => onValuesConfirmClick(e)} />
    } else {
      const value = selectedRow ? selectedRow.value : 0
      return <CounterValueInputDialog open={open} counterId={props.counter.id} counterValueListItem={selectedRow} onClose={() => setOpen(false)} onConfirm={(e: any) => onValueConfirmClick(e)} />
    }
  }

  return (
    <div>
      <Grid container direction='row' alignItems='stretch' spacing={1}>
        <Grid item xs={12}>
          {renderAlert()}
        </Grid>
        {canEdit ? (
          <Grid item xs={12}>
            <Button id='saveNewReading' onClick={handleSaveNewReadingClick} startIcon={<Add fontSize='small' />}>
              Ny avläsning
            </Button>
          </Grid>
        ) : (
          ''
        )}
      </Grid>
      <Grid container direction='row' alignItems='stretch' spacing={1}>
        <Grid item xs={12}>
          {renderReadings()}
        </Grid>
      </Grid>
      {renderInputDialog()}
    </div>
  )
}
export default CounterReadings
