import {Box} from '@mui/material'

import {styled} from '@mui/material/styles'

export const SidebarWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'sidebarWidth'
})<{ sidebarWidth?: number}>(
  ({theme, sidebarWidth}) => `
        display: flex;
        flex-direction: column;
        width: ${sidebarWidth ?? theme.sidebar.width}
        height: 100%;
        background: ${theme.sidebar.background};
        top:${theme.header.height};
        border-right: ${theme.sidebar.borderColor};
        color:'#fffff';
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            position: fixed;
            z-index: 10;
        }
`
)
