import React, {useEffect, useState} from 'react'
import {Navigate, Outlet} from 'react-router-dom'
import {getAuthToken, isAuthorized, isTokenExpired} from '../../shared/utils/utilities'
import {useIsAuthenticated, useMsal} from '@azure/msal-react'
import {IAuth} from '../../redux/login/loginInterfaces'
import {InteractionRequiredAuthError, InteractionStatus} from '@azure/msal-browser'
import msalInstance, {tokenRequest} from '../auth/msalConfig'
import NoAccess from '../error/NoAccess'

// Protected route component
const PrivateRoutes = () => {
  const {accounts, instance, inProgress} = useMsal()
  const [hasToken, setHasToken] = useState<boolean | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const auth: IAuth = localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth') as string) : null

  useEffect(() => {
    if (inProgress !== InteractionStatus.None) return

    const checkToken = async () => {
      setLoading(true)
      let account = msalInstance.getActiveAccount()
      if (!account) {
        const accounts = msalInstance.getAllAccounts()
        if (accounts?.length > 0) {
          account = accounts[0]
        }
      }
      if (account) {
        try {
          await msalInstance.acquireTokenSilent({...tokenRequest, account: account ? account : undefined})
          setHasToken(true)
        } catch (error) {
          console.log(error)
          if (error instanceof InteractionRequiredAuthError) {
            setHasToken(false)
          }
        }
      } else {
        setHasToken(false)
      }
      setLoading(false)
    }

    checkToken()
  }, [instance, accounts, inProgress])

  if (loading) {
    console.log("LOADING")
    return null
  }

  if (!auth) {
    console.log("NO AUTH")
    return <Navigate to='/login' />
  }

  if (auth.isAAD) {
    console.log("IS AAD")
    if (hasToken === false) {
      console.log("TOKEN IS FALSE")
      return <Navigate to='/login' replace />
    }
  } else {
    if (!getAuthToken() || isTokenExpired()) {
      console.log("EXPIRED OR NO TOKEN")
      return <Navigate to='/login' />
    }
  }

  if (!isAuthorized()) {
    console.log("NOT AUTHORIZED AT ALL")
    return <NoAccess />
  }

  return <Outlet />
}

export default PrivateRoutes