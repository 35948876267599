import userService from '../../services/userService'
import {Dispatch} from 'redux'
import {ActionTypes} from './types'
import attributeService from '../../services/attributeService'
import IAttribute from '../attribute/interfaces'
import {USER, OFFICE, NODE_WRITE, DEVIATION_READ, CUSTOMER, CUSTOMERS, UPN} from '../../shared/utils/constants'
import {IUser, UserAttribute, UserListItem, UserPasswordResetResult, UserRole} from './interfaces'
import {IOwner} from '../owner/ownerInterfaces'
import axios from 'axios'
import {isNullOrWhiteSpace} from '../../shared/utils/utilities'
import { SaveResponse } from 'src/shared/enums/SaveResponse'

export const searchUsers = (keyword: string) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.SEARCH_USERS_LOADING})
  try {
    const users = await userService.search(keyword)
    return dispatch({type: ActionTypes.SEARCH_USERS_SUCCESS, payload: users})
  } catch (error) {
    return dispatch({type: ActionTypes.SEARCH_USERS_FAILED, payload: error})
  }
}
export const getCurrentUserOwners = () => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.GET_CURRENT_USER_OWNERS_LOADING})
  try {
    const owners = await userService.getCurrentUserOwners()
    return dispatch({type: ActionTypes.GET_CURRENT_USER_OWNERS_SUCCESS, payload: owners})
  } catch (error) {
    return dispatch({type: ActionTypes.GET_CURRENT_USER_OWNERS_FAILED, payload: error})
  }
}
export const getUserById = (id: number) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.GET_USER_BY_ID_LOADING})
  try {
    let user: UserListItem = await userService.getUserById(id)

    // Handle user office
    const attributes: IAttribute[] = await attributeService.getAttributes()
    const officeAttribute = attributes.find((x: IAttribute) => x.class === USER && x.name === OFFICE)
    if (officeAttribute) {
      const userAttributes: UserAttribute[] = await userService.getUserAttributes(id)
      const officeAttributeValue = userAttributes.find((x: UserAttribute) => x.attributeId === officeAttribute.id)
      if (officeAttributeValue) {
        user.office = officeAttributeValue.value
      }
    }

    // Handle user roles
    const roles: UserRole[] = await userService.getUserRoles()
    const currentUserRole = roles.find((role: UserRole) => role.id === user.userRoleId)
    if (currentUserRole) {
      user.role = currentUserRole.name
    }

    // Handle user owners
    const owners: IOwner[] = await userService.getOwnersByUserId(id)
    if (owners) {
      user.owners = owners.map((x) => x.id)
    }

    const canEditAttribute = attributes.find((x: IAttribute) => x.class === USER && x.name === NODE_WRITE)
    if (canEditAttribute) {
      const userAttributes: UserAttribute[] = await userService.getUserAttributes(id)
      const canEditAttributeValue = userAttributes.find((x: UserAttribute) => x.attributeId === canEditAttribute.id)
      if (canEditAttributeValue) {
        user.canEdit = canEditAttributeValue.value === 'true'
      }
    }

    const canReadDeviationAttribute = attributes.find((x: IAttribute) => x.class === USER && x.name === DEVIATION_READ)
    if (canReadDeviationAttribute) {
      const userAttributes: UserAttribute[] = await userService.getUserAttributes(id)
      const canReadDeviationAttributeValue = userAttributes.find((x: UserAttribute) => x.attributeId === canReadDeviationAttribute.id)
      if (canReadDeviationAttributeValue) {
        user.canReadDeviations = canReadDeviationAttributeValue.value === 'true'
      }
    }

    const upnAttribute = attributes.find((x: IAttribute) => x.class === USER && x.name === UPN)
    if (upnAttribute) {
      const userAttributes: UserAttribute[] = await userService.getUserAttributes(id)
      const upnAttributeValue = userAttributes.find((x: UserAttribute) => x.attributeId === upnAttribute.id)
      if (upnAttributeValue) {
        user.userPrincipalName = upnAttributeValue.value
      }
    }

    return dispatch({type: ActionTypes.GET_USER_BY_ID_SUCCESS, payload: user})
  } catch (error) {
    return dispatch({type: ActionTypes.GET_USER_BY_ID_FAILED, payload: error})
  }
}

export const getUsers = () => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.GET_USERS_LOADING})
  try {
    const users = await userService.getUsers()
    return dispatch({type: ActionTypes.GET_USERS_SUCCESS, payload: users})
  } catch (error) {
    return dispatch({type: ActionTypes.GET_USERS_FAILED, payload: error})
  }
}
  
export const getUserListItems = () => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.GET_USER_LIST_ITEMS_LOADING})
  try {
    const users = await userService.getUsersListIitems()
    return dispatch({type: ActionTypes.GET_USER_LIST_ITEMS_SUCCESS, payload: users})
  } catch (error) {
    return dispatch({type: ActionTypes.GET_USER_LIST_ITEMS_FAILED, payload: error})
  }
}  

export const getUserRoles = () => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.GET_USER_ROLES_LOADING})
  try {
    const userRoles = await userService.getUserRoles()
    return dispatch({type: ActionTypes.GET_USER_ROLES_SUCCESS, payload: userRoles})
  } catch (error) {
    return dispatch({type: ActionTypes.GET_USER_ROLES_FAILED, payload: error})
  }
}

export const getUserAttributes = (userId: number) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.GET_USER_ATTRIBUTES_LOADING})
  try {
    const attributes = await userService.getUserAttributes(userId)
    return dispatch({type: ActionTypes.GET_USER_ATTRIBUTES_SUCCESS, payload: attributes})
  } catch (error) {
    return dispatch({type: ActionTypes.GET_USER_ATTRIBUTES_FAILED, payload: error})
  }
}

export const createUser =
  (username: string, fullName: string, password: string, userRoleId: number, office: string, owners: number[], shouldEdit: boolean, shouldReadDeviations: boolean, userPrincipalName: string) =>
  async (dispatch: Dispatch) => {
    dispatch({type: ActionTypes.CREATE_USER_LOADING})
    try {
      const newUser: IUser = await userService.createUser({mail: username, fullName: fullName, userRoleId: userRoleId, password: password, isDisabled: false})
      if (newUser) {
        const attributes: IAttribute[] = await attributeService.getAttributes()
        if (attributes) {
          const officeAttribute = attributes.find((x: IAttribute) => x.class === USER && x.name === OFFICE)
          if (officeAttribute) {
            const createdOfficeAttribute = await userService.createUserAttribute(newUser.id, officeAttribute.id, office)
          }
        }
        if (owners?.length > 0) {
          for (let i = 0; i < owners.length; i++) {
            if (owners[i] > 0) {
              const createdOwnerAttribute = await userService.createUserOwner(newUser.id, owners[i])
            }
          }
        }

        // Check whether the userRoleId belongs to the UserRole customer
        const roles: UserRole[] = await userService.getUserRoles()
        const roleofCreatedUser = roles.find((role: UserRole) => role.id === userRoleId)
        let userAttributes: UserAttribute[] = []
        // Check if name of roleofCreatedUser is constants CUSTOMER or CUSTOMERS
        if (roleofCreatedUser && (roleofCreatedUser.name.toLocaleUpperCase() === CUSTOMER || roleofCreatedUser.name.toLocaleUpperCase() === CUSTOMERS)) {
          const canEditAttribute = attributes.find((x: IAttribute) => x.class === USER && x.name === NODE_WRITE)
          const canReadDeviationAttribute = attributes.find((x: IAttribute) => x.class === USER && x.name === DEVIATION_READ)
          userAttributes = await userService.getUserAttributes(newUser.id)
          if (canEditAttribute) {
            const canEditAttributeValue = userAttributes.find((x: UserAttribute) => x.attributeId === canEditAttribute.id)
            // If canEditAttributeValue is not set, and canEdit is true, create the attribute
            if ((!canEditAttributeValue && shouldEdit === true) || (canEditAttributeValue && canEditAttributeValue.value !== shouldEdit.toString())) {
              const createdCanEditAttribute = await userService.createUserAttribute(newUser.id, canEditAttribute.id, shouldEdit.toString())
            }
          }

          if (canReadDeviationAttribute) {
            const canReadDeviationAttributeValue = userAttributes.find((x: UserAttribute) => x.attributeId === canReadDeviationAttribute.id)
            // If canReadDeviationAttributeValue is not set, and canReadDeviations is true, create the attribute
            if ((!canReadDeviationAttributeValue && shouldReadDeviations === true) || (canReadDeviationAttributeValue && canReadDeviationAttributeValue.value !== shouldReadDeviations.toString())) {
              const createdCanReadDeviationAttribute = await userService.createUserAttribute(newUser.id, canReadDeviationAttribute.id, shouldReadDeviations.toString())
            }
          }

          const upnAttribute = attributes.find((x: IAttribute) => x.class === USER && x.name === UPN)
          if (upnAttribute) {
            if (userAttributes?.length === 0) {
              userAttributes = await userService.getUserAttributes(newUser.id)
            }
            const upnAttributeValue = userAttributes.find((x: UserAttribute) => x.attributeId === upnAttribute.id)
            // If upnAttributeValue is not set, or upn is empty, create the attribute
            if ((!upnAttributeValue && !isNullOrWhiteSpace(userPrincipalName)) || (upnAttributeValue && upnAttributeValue.value !== userPrincipalName.toString())) {
              const createdupnAttribute = await userService.createUserAttribute(newUser.id, upnAttribute.id, userPrincipalName.toString())
            }
          }
        }
      }

      return dispatch({type: ActionTypes.CREATE_USER_SUCCESS, payload: newUser})
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return dispatch({type: ActionTypes.CREATE_USER_FAILED, payload: error.response?.data})
      } else {
        return dispatch({type: ActionTypes.CREATE_USER_FAILED, payload: error})
      }
    }
  }

export const updateUser =
  (id: number, username: string, fullName: string, userRoleId: number, office: string, owners: number[], shouldEdit: boolean, shouldReadDeviations: boolean, userPrincipalName: string) =>
  async (dispatch: Dispatch) => {
    dispatch({type: ActionTypes.UPDATE_USER_LOADING})
    try {
      const updatedUser: IUser = await userService.updateUser({id: id, mail: username, fullName: fullName, userRoleId: userRoleId, isDisabled: false})
      if (updatedUser) {
        const attributes: IAttribute[] = await attributeService.getAttributes()
        if (attributes) {
          const officeAttribute = attributes.find((x: IAttribute) => x.class === USER && x.name === OFFICE)
          if (officeAttribute) {
            const createdOfficeAttribute = await userService.createUserAttribute(updatedUser.id, officeAttribute.id, office)
          }

          // Check whether the userRoleId belongs to the UserRole customer
          const roles: UserRole[] = await userService.getUserRoles()
          const roleOfUpdatedUser = roles.find((role: UserRole) => role.id === userRoleId)
          let userAttributes: UserAttribute[] = []
          // Check if name of roleOfUpdatedUser is constants CUSTOMER or CUSTOMERS
          if (roleOfUpdatedUser && (roleOfUpdatedUser.name.toLocaleUpperCase() === CUSTOMER || roleOfUpdatedUser.name.toLocaleUpperCase() === CUSTOMERS)) {
            const canEditAttribute = attributes.find((x: IAttribute) => x.class === USER && x.name === NODE_WRITE)
            const canReadDeviationAttribute = attributes.find((x: IAttribute) => x.class === USER && x.name === DEVIATION_READ)

            userAttributes = await userService.getUserAttributes(id)

            if (canEditAttribute) {
              const canEditAttributeValue = userAttributes.find((x: UserAttribute) => x.attributeId === canEditAttribute.id)
              // If canEditAttributeValue is not set, and canEdit is true, create the attribute
              if ((!canEditAttributeValue && shouldEdit === true) || (canEditAttributeValue && canEditAttributeValue.value !== shouldEdit.toString())) {
                const createdCanEditAttribute = await userService.createUserAttribute(updatedUser.id, canEditAttribute.id, shouldEdit.toString())
              }
            }

            if (canReadDeviationAttribute) {
              const canReadDeviationAttributeValue = userAttributes.find((x: UserAttribute) => x.attributeId === canReadDeviationAttribute.id)
              // If canReadDeviationAttributeValue is not set, and canReadDeviations is true, create the attribute
              if ((!canReadDeviationAttributeValue && shouldReadDeviations === true) || (canReadDeviationAttributeValue && canReadDeviationAttributeValue.value !== shouldReadDeviations.toString())) {
                const createdCanReadDeviationAttribute = await userService.createUserAttribute(updatedUser.id, canReadDeviationAttribute.id, shouldReadDeviations.toString())
              }
            }
          }
          const upnAttribute = attributes.find((x: IAttribute) => x.class === USER && x.name === UPN)

          if (upnAttribute) {
            if (userAttributes?.length === 0) {
              userAttributes = await userService.getUserAttributes(id)
            }
            const upnAttributeValue = userAttributes.find((x: UserAttribute) => x.attributeId === upnAttribute.id)
            // If upnAttributeValue is not set, or upn is empty, create the attribute
            if ((!upnAttributeValue && !isNullOrWhiteSpace(userPrincipalName)) || (upnAttributeValue && upnAttributeValue.value !== userPrincipalName.toString())) {
              const createdupnAttribute = await userService.createUserAttribute(updatedUser.id, upnAttribute.id, userPrincipalName.toString())
            }
          }
        }
        if (owners?.length > 0) {
          userService.updateUserOwners(updatedUser.id, owners)
        }
      }
      return dispatch({type: ActionTypes.UPDATE_USER_SUCCESS, payload: updatedUser})
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return dispatch({type: ActionTypes.UPDATE_USER_FAILED, payload: error.response?.data})
      } else {
        return dispatch({type: ActionTypes.UPDATE_USER_FAILED, payload: error})
      }
    }
  }

export const updatePassword = (userId: number, password: string) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.UPDATE_USER_PASSWORD_LOADING})
  try {
    const passwordResetResult: string = await userService.updateUserPassword(userId, password)
    const result: UserPasswordResetResult = {userId: userId, responseMessage: passwordResetResult}
    return dispatch({type: ActionTypes.UPDATE_USER_PASSWORD_SUCCESS, payload: result})
  } catch (error) {
    return dispatch({type: ActionTypes.UPDATE_USER_PASSWORD_FAILED, payload: error})
  }
}

export const updateUserState = (id: number, isDisabled: boolean) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.DISABLE_USER_LOADING})
  try {
    const disabledUser: IUser = await userService.updateUserState(id, isDisabled)
    return dispatch({type: ActionTypes.DISABLE_USER_SUCCESS, payload: disabledUser})
  } catch (error) {
    return dispatch({type: ActionTypes.DISABLE_USER_FAILED, payload: error})
  }
}

export const deleteUser = (id: number) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.DELETE_USER_LOADING})
  try {
    await userService.deleteUser(id)
    return dispatch({type: ActionTypes.DELETE_USER_SUCCESS, payload: id})
  } catch (error) {
    return dispatch({type: ActionTypes.DELETE_USER_FAILED, payload: error})
  }
}
export const selfResetCurrentUserActions = (oldPassword: string, newPassword: string, repeatNewPassword: string) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.UPDATE_CURRENT_USER_PASSWORD_LOADING})
  try {
    const passwordResetResult: string = await userService.updateCurrentUserPassword("", oldPassword, newPassword, repeatNewPassword)    
    const userPasswordResetResult = {userId: 0, responseMessage: passwordResetResult}
    return dispatch({type: ActionTypes.UPDATE_CURRENT_USER_PASSWORD_SUCCESS, payload: userPasswordResetResult})
  } catch (error) {
    return dispatch({type: ActionTypes.UPDATE_CURRENT_USER_PASSWORD_FAILED, payload: error})
  }
}
export const sendResetMail = () => async (dispatch: Dispatch) => {
  dispatch({ type: ActionTypes.SEND_USER_RESET_LOADING})
  try {
    const result : string = await userService.sendCurrentUserPasswordReset()
    return dispatch({ type: ActionTypes.SEND_USER_RESET_SUCCESS, payload: result})
  } catch (error) {
    return dispatch({ type: ActionTypes.SEND_USER_RESET_FAILED, payload: error})
  }  
}