import React, {FC, useEffect, useState} from 'react'
import {useForm, SubmitHandler} from 'react-hook-form'
import {useNavigate} from 'react-router-dom'
import {IAuth, ILogin} from '../../redux/login/loginInterfaces'
import {Button, TextField} from '@mui/material'
import {useDispatch, useSelector} from 'react-redux'
import {login, setAADLoginStatus} from '../../redux/login/loginActions'
import './Login.scss'
import {CloudCircle} from '@mui/icons-material'
import {useMsal, useIsAuthenticated} from '@azure/msal-react'
import {loginRequest, tokenRequest} from '../auth/msalConfig'
import {AccountInfo, AuthenticationResult, AuthError, ClientAuthError, InteractionRequiredAuthError, InteractionStatus, ServerError} from '@azure/msal-browser'
import {isTokenExpired, isAuthorized, getAuthToken, isNullOrWhiteSpace, isValidEmail} from '../../shared/utils/utilities'
import userService from '../../services/userService'
import {Me} from '../../redux/user/interfaces'
import jwtDecode from 'jwt-decode'
import {alignProperty} from '@mui/material/styles/cssUtils'
import LoginPasswordResetDialog from './LoginPasswordResetDialog'
import {RootState} from '../../redux/rootReducer'
import LoginPasswordResetProgressDialog from './LoginPasswordResetProgressDialog'
import {sendResetMail} from '../../redux/user/actions'
const LoginForm: FC = (): any => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {instance, accounts, inProgress} = useMsal()

  const {register, handleSubmit, watch} = useForm<ILogin>()
  const [attempts, setAttempts] = useState<number>(0)
  const [isDisabled, setIsDisabled] = useState<boolean>(false)
  const [useAzureAuth, setUseAzureAuth] = useState<boolean>(true)
  const [passwordResetDialogOpen, setPasswordResetDialogOpen] = useState<boolean>(false)
  const [passwordResetProgressDialogOpen, setPasswordResetProgressDialogOpen] = useState<boolean>(false)
  const [promptText, setPromptText] = useState<string>('Ett återställningsmail har skickats till din registrerade adress.')
  const [username, setUserName] = useState<string>('')

  const currentUserName = watch("username", "")
  const lastReset = useSelector((state: RootState) => state.user.sendUserReset)
  // const activeAccount = instance.getActiveAccount()
  const isAuthenticated = useIsAuthenticated()

  interface JwtPayload {
    exp: number
    [key: string]: any
  }

  useEffect(() => setUseAzureAuth(true), [])

  useEffect(() => {
    dispatch(setAADLoginStatus(null))
    if (!useAzureAuth) return

    if (!isAuthenticated || inProgress !== InteractionStatus.None || accounts?.length === 0) return

    const initializeToken = async () => {
      let accessToken: string | null = null

      try {
        // Await the result of handleRedirectPromise
        const response = await instance.handleRedirectPromise()
        if (response) {
          instance.setActiveAccount(response.account)
          accessToken = response.accessToken
        }
      } catch (error) {
        console.error('Error during redirect handling:', error)
      }

      if (!accessToken) {
        try {
          await instance.acquireTokenRedirect(tokenRequest)
        } catch (error) {
          console.error('Redirect token acquisition failed.', error)
        }
      }

      if (isAuthenticated && accessToken) {
        await acquireToken()
      }
    }
    initializeToken()
  }, [isAuthenticated, inProgress, instance, dispatch])

  const handleTokenResponse = async (tokenResponse: any) => {
    const expiresOn = new Date(tokenResponse.expiresOn ?? new Date())

    const tryParseUniqueId = (uniqueId: string) => {
      try {
        return parseInt(uniqueId)
      } catch {
        return 0
      }
    }

    const auth: IAuth = {
      id: tryParseUniqueId(tokenResponse.account?.homeAccountId?.split('.')[0] ?? ''),
      isLoggedIn: tokenResponse.account !== null,
      refreshToken: '',
      expires: expiresOn,
      role: '',
      token: '',
      permissions: [],
      userName: tokenResponse.account?.username,
      isAAD: true,
    }

    localStorage.setItem('auth', JSON.stringify(auth))

    const me: Me = await userService.getMe()
    if (!me) {
      dispatch(setAADLoginStatus('Inloggat konto saknar åtkomst.'))
      return
    }

    auth.id = me.id
    auth.permissions = me.permissions
    auth.role = me.role
    localStorage.setItem('auth', JSON.stringify(auth))

    navigate('/home')
  }

  const handleTokenError = (err: any) => {
    if (err instanceof InteractionRequiredAuthError) {
      dispatch(setAADLoginStatus('Interaction required to obtain token: ' + err.message))
    } else if (err instanceof ClientAuthError) {
      dispatch(setAADLoginStatus('Client authentication error: ' + err.message))
    } else if (err instanceof ServerError) {
      dispatch(setAADLoginStatus('Server error: ' + err.message))
    } else if (err?.message) {
      dispatch(setAADLoginStatus('An unknown error occurred while acquiring access token: ' + err.message))
      console.log(err)
    } else {
      dispatch(setAADLoginStatus('An unknown error occurred while acquiring access token.'))
      console.log(err)
    }
  }
  const acquireToken = async () => {
    let activeAccount = instance.getActiveAccount()
    if (!activeAccount && accounts.length > 0) {
      instance.setActiveAccount(accounts[0])
      activeAccount = instance.getActiveAccount()
    }

    if (!activeAccount) {
      dispatch(setAADLoginStatus('No active account found. Please log in.'))
      setIsDisabled(false)
      return
    }

    const myTokenRequest = {
      scopes: tokenRequest.scopes,
      account: activeAccount,
    }

    let aquireTokenFailed = false

    let tokenResponse: AuthenticationResult | null = null

    try {
      tokenResponse = await instance.acquireTokenSilent(myTokenRequest)
    } catch (err) {
      handleTokenError(err)
      aquireTokenFailed = true
    }

    // If aquire token failed, attempt it with the next account in the accounts array.
    if (aquireTokenFailed) {
      for (let i = 1; i < accounts.length; i++) {
        myTokenRequest.account = accounts[i]
        try {
          tokenResponse = await instance.acquireTokenSilent(myTokenRequest)
          break
        } catch (err) {
          handleTokenError(err)
        }
      }
    }
    try {
      await handleTokenResponse(tokenResponse)
    } catch (err: any) {
      dispatch(setAADLoginStatus(`Error when resolving user: ${err.message}`))
      console.log(err)
    }
  }

  const onSubmit: SubmitHandler<ILogin> = async (data) => {
    setIsDisabled(true)
    setUseAzureAuth(false)
    dispatch(login(data))
    setTimeout(() => {
      setAttempts(attempts + 1)
      setIsDisabled(false)
    }, attempts * 1000)
  }

  const onAzureLogin = async () => {
    setIsDisabled(true)
    try {
      await instance.loginRedirect(loginRequest)
    } catch (err) {
      if (err instanceof ClientAuthError) {
        dispatch(setAADLoginStatus(`Client error: ${err.message}`))
      } else if (err instanceof ServerError) {
        dispatch(setAADLoginStatus(`Server error: ${err.message}`))
      } else if (err instanceof InteractionRequiredAuthError) {
        dispatch(setAADLoginStatus(`Interaction required: ${err.message}`))
      } else if (err instanceof AuthError) {
        dispatch(setAADLoginStatus(`Authentication error: ${err.message}`))
      } else {
        dispatch(setAADLoginStatus('An unknown error occurred during login redirect.'))
      }
      setIsDisabled(false)
    }
  }

  const onPasswordResetClick = () => {
    if (isNullOrWhiteSpace(currentUserName)) {
      setPromptText('E-postadress saknas. Vänligen fyll i e-postadress i fältet för användarnamn och försök igen.')
      setPasswordResetProgressDialogOpen(true)
      return
    }
    else if(!isValidEmail(currentUserName)){
      setPromptText('Användarnamnet är inte en giltig e-postadress. Vänligen fyll i en giltig e-postadress och försök igen.')
      setPasswordResetProgressDialogOpen(true)
      return
    }
    setPasswordResetDialogOpen(true)
  }

  const onPasswordResetConfirm = () => {
    setPasswordResetDialogOpen(false)

    let tenMinutesAgo = new Date()
    tenMinutesAgo.setMinutes(tenMinutesAgo.getMinutes() - 10)

    if (lastReset && lastReset.lastMail && lastReset.lastMail > tenMinutesAgo) {
      setPromptText('En lösenordsåterställning har redan skickats. Vänligen vänta innnan du försöker igen.')
      setPasswordResetProgressDialogOpen(true)
    } else {
      setPromptText('Ett återställningsmail har skickats till din registrerade adress.')
      setPasswordResetProgressDialogOpen(true)
      // dispatch(sendResetMail)
    }
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField margin='normal' required fullWidth label='Användarnamn' {...register('username', {required: true})} />
        <TextField margin='normal' required fullWidth label='Lösenord' type='password' {...register('password', {required: true})} />
        <a href='#' onClick={onPasswordResetClick}>
          Återställ lösenord
        </a>
        <Button type='submit' fullWidth variant='contained' sx={{mt: 3, mb: 2}} disabled={isDisabled} style={{backgroundColor: '#00d170'}}>
          Logga in
        </Button>
        <Button
          fullWidth
          variant='contained'
          sx={{mt: 3, mb: 2}}
          disabled={isDisabled}
          style={{backgroundColor: '#007FFF', color: 'white'}}
          startIcon={<CloudCircle fontSize='small' />}
          onClick={onAzureLogin}>
          Logga in med Microsoft Entra ID
        </Button>
      </form>
      <LoginPasswordResetDialog setOpen={setPasswordResetDialogOpen} open={passwordResetDialogOpen} onConfirm={onPasswordResetConfirm} />
      <LoginPasswordResetProgressDialog setOpen={setPasswordResetProgressDialogOpen} open={passwordResetProgressDialogOpen} text={promptText} />
    </div>
  )
}
export default LoginForm
