import {styled} from '@mui/material/styles'
import {Box} from '@mui/material'

export const MainWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'sidebarWidth'
})<{ sidebarWidth?: number }>(
  ({theme, sidebarWidth}) => `
    flex: 1 1 auto;
    display: flex;
    padding-left: ${sidebarWidth ?? 0}px;
    position: ${sidebarWidth ? 'relative' : 'static'};
    @media (min-width: ${theme.breakpoints.values.lg}px) {
      padding-left: ${sidebarWidth}px;
    }
    overflow-y: 'hidden';
`
)

export const MainContent = styled(Box)(
  ({theme}) => `
    margin-top: ${theme.header.height};
    flex: 1 1 auto;
    overflow-y: auto;
    overflow-x: hidden; 
    height: calc(100vh - ${theme.header.height});
`
)

export const Pagetitle = styled(Box)(
  ({theme}) => `
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 48px;
    padding: 0 20px;
    background: ${theme.sidebar.background};
    border-bottom: ${theme.sidebar.borderColor};
`
)