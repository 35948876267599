export const LOGIN = "LOGIN";
export const LOGGING_IN = "LOGGING_IN";
export const LOGOUT = "LOGOUT";
export const LOGIN_SUCCEDED = "LOGIN_SUCCEDED";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGOUT_FAILED = "LOGOUT_FAILED";
export const LOGIN_UNAUTHORIZED = "LOGIN_UNAUTHORIZED";
export const AAD_LOGIN_LOADING = "AAD_LOGIN_LOADING";
export const AAD_LOGIN_SUCCEDED = "AAD_LOGIN_SUCCEDED";
export const AAD_LOGIN_FAILED = "AAD_LOGIN_FAILED";
export const AAD_LOGOUT_LOADING = "AAD_LOGOUT_LOADING";
export const AAD_LOGOUT_SUCCEDED = "AAD_LOGOUT_SUCCEDED";
export const AAAD_LOGOUT_FAIELD = "AAD_LOGOUT_FAILED";
export const SET_AAD_LOGIN_STATUS = "SET_AAD_LOGIN_STATUS";