import CaseModal from '../../case/CaseModal'
import {Card, CardContent, CardHeader, Container, Divider, Grid, styled} from '@mui/material'
import {DataGrid, GridColDef} from '@mui/x-data-grid'
import {IConsumptionNodeDto, IConsumptionTableProps} from '../../../redux/consumption/interfaces'
import ConsumptionNodeDto from '../../../redux/consumption/ConsumptionNodeDto'
import { ITableLog} from '../../../redux/counterValue/interfaces'
import {logIsInConsumptionPeriod} from '../../../shared/utils/utilities'
import {first, uniq} from 'lodash'
import React, {useEffect, useState} from 'react'
import ConsumptionTableCell from '../table/ConsumptionTableCell'
import {getFormattedData} from '../table/consumptionTableHelper'
import ConsumptionTitle from '../ConsumptionTitle'
import {useDispatch, useSelector} from 'react-redux'
import {setPdfTableState} from '../../../redux/pdf/actions'
import {IConsumptionTablePDFProps} from './interfaces'
import {Cell, Column, useTable, TableOptions, HeaderGroup, UseTableHeaderGroupProps} from 'react-table'
import ReactTable from 'react-table'
import ConsumptionWithLog from '../../../redux/counterValue/ConsumptionWithLog'
import { RootState } from '../../../redux/rootReducer'

const ConsumptionTablePDF = (props: IConsumptionTablePDFProps) => {
  // const [rows, setRows] = useState<{[key: string]: string | ConsumptionWithLog}[]>([])
  // const [columns, setColumns] = useState<GridColDef[]>([])
  const showVolume = useSelector((state: RootState) => state.configuration.showVolume)
  const combineLogs = (consumptions: ConsumptionNodeDto[], logs: ITableLog[]) => {
    const combined: ConsumptionWithLog[] = consumptions.map((c) => {
      const cLogs = logs.filter((l) => {
        return logIsInConsumptionPeriod(c, l)
      })
      return new ConsumptionWithLog({...c, aTemp: c.aTemp, loa: c.loa, estimatedATemp: c.estimatedATemp, estimatedLOA: c.estimatedLOA, logs: cLogs }, c.multiplier)
    })
    return combined
  }

  const tableData = React.useMemo(() => {
    const fromYear = first(
      props.data
        .filter(x => !x.hidden)
        .map((x) => x.year)
        .sort()
    )
    if(!fromYear) return []
    const combinedRows: ConsumptionWithLog[] = combineLogs(props.data, props.logs ?? [])
    return getFormattedData(combinedRows, fromYear, props.category, props.meterType, props.unit, false, showVolume)
  }, [props.data])

  const tableColumns: Array<Column<{[key: string]: string | ConsumptionWithLog}>> = React.useMemo(() => {
    const columns: Array<Column<{[key: string]: string | ConsumptionWithLog}>> = []
    columns.push({accessor: 'month', Header: 'Månad'})
    //const yearColumns: Array<Column<{[key: string]: string | ConsumptionWithLog}>> = []
    columns.push(
      ...uniq(
        props.data
          .filter(x => !x.hidden)
          .map((x) => x.year)
          .sort()
      ).map((year) => ({
        accessor: year.toString(),
        Header: year.toString(),
        Cell: ({ value }: { value: string | ConsumptionWithLog }) => {
         return <div>
          {getValue(year, value)}
         </div>
        }})))

    columns.push({accessor: 'diffPeriod', Header: 'Diff/Period'})
    columns.push({accessor: 'diffYear', Header: 'Diff/rullande 12 månader'})
    return columns
  }, [props.data])

  const tableInstance = useTable({columns: tableColumns, data: tableData})
  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} = tableInstance

  function getValue(year: number, value : string | ConsumptionWithLog) {
    if(value instanceof ConsumptionWithLog) {
      return <ConsumptionTableCell data={value} nodeType={props.data?.length > 0 ? props.data[0].type : ''} category={props.category} year={year} onTooltipButtonClick={() => {}} meterType={props.meterType} unit={props.unit} />
    }
    else if(typeof value === 'string') return value
    return "0"
  }

  return (
    <div style={{display: 'block', maxWidth: '100%'}}>
      <div style={{'textAlign': 'center'}}>
        <ConsumptionTitle category={props.category} meter={props.meterType} unit={props.unit} currency={props.currency} emission={props.emission} />
      </div>
      <table {...getTableProps()} style={{width: '100%', borderSpacing: 0}}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()} style={{width: '1%', alignContent: 'left'}}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default ConsumptionTablePDF
