import React, { useEffect } from 'react'
import {Link, Navigate, Route, Routes} from 'react-router-dom'
import SidebarLayout from '../shared/layouts/SidebarLayout'
import Home from '../components/home/Home'
import Owner from '../components/owner/Owner'
import Estate from '../components/estate/Estate'
import Address from '../components/address/Address'
import Counter from '../components/counter/Counter'
import Internal from '../components/error/Internal'
import Settings from '../components/settings'
import CreateArea from '../components/area/CreateArea'
import UpdateArea from '../components/area/EditArea'
import MeasurePoint from '../components/measurepoint/MeasurePoint'
import OwnerList from '../components/owner/OwnerList'
import CreateEstate from './estate/create/CreateEstate'
import CreateOwner from '../components/owner/CreateOwner'
import AreaList from '../components/area/AreaList'
import MeterList from '../components/meter/MeterList'
import Meter from '../components/meter/Meter'
import CreateAddress from '../components/address/CreateAddress'
import CreateMeasurePoint from '../components/measurepoint/CreateMeasurePoint'
import CreateCounter from '../components/counter/CreateCounter'
import Login from './login/Login'
import {Role} from '../shared/enums/Role'
import PrivateRoutes from './shared/PrivateRoutes'
import UnderConstruction from './construction/UnderConstruction'
import UserContainer from './user/UserContainer'
import UserDetailsContainer from './user/userDetails/UserDetailsContainer'
import CreateUserContainer from './user/createUser/CreateUserContainer'
import AdminRoutes from './shared/AdminRoutes'
import ReportsContainer from './report/ReportsContainer'
import OfficeContainer from './office/OfficesContainer'
import CreateOfficeContainer from './office/createOffice/CreateOfficeContainer'
import Office from './office/Office'
import CustomerRestrictedRoutes from './shared/CustomerRestrictedRoutes'
import Start from './home/Start'
import NoAccess from './error/NoAccess'
const App = () => {
  return (
    <Routes>
      <Route path='/login' element={<Login />} />
      <Route path='/noaccess' element={<NoAccess />} />
      <Route element={<PrivateRoutes />}>
        <Route path='/' element={<SidebarLayout />}>
          <Route element={<CustomerRestrictedRoutes />}>
            <Route path='/' element={<Home />} />
            <Route path='/home' element={<Home />} />
          </Route>
          <Route path='/start' element={<Start />} />
          <Route path='/area' element={<AreaList />} />
          <Route path='/area/:id/edit' element={<UpdateArea />} />
          <Route path='/owner/:id' element={<Owner />} />
          <Route path='/owner' element={<OwnerList />} />
          <Route path='/estate' element={<Estate />} />
          <Route path='/estate/:id' element={<Estate />} />
          <Route path='/address/:id' element={<Address />} />
          <Route path='/measurepoint/:id' element={<MeasurePoint />} />
          <Route path='/counter/:id' element={<Counter />} />
          <Route path='/settings' element={<Settings />} />
          <Route path='/meter' element={<MeterList />} />
          <Route path='/meter/:id' element={<Meter />} />
          <Route path='/error/501' element={<Internal />} />
          <Route path='/report' element={<ReportsContainer />} />
          <Route path='/office' element={<OfficeContainer />} />
          <Route path='/office/:id/edit' element={<Office />} />
          {/* <Route path='*' element={<Navigate to='/home' replace />} /> */}
          <Route element={<AdminRoutes />}>
            <Route path='/address/:id/measurepoint/create' element={<CreateMeasurePoint />} />
            <Route path='/measurepoint/:id/counter/create' element={<CreateCounter />} />
            <Route path='/area/create' element={<CreateArea />} />
            <Route path='/owner/create' element={<CreateOwner />} />
            <Route path='/estate/:id/address/create' element={<CreateAddress />} />
            <Route path='/owner/:id/estate/create' element={<CreateEstate />} />
            <Route path='/office/create' element={<CreateOfficeContainer />} />
            <Route path='/user' element={<UserContainer />} />
            <Route path='/user/:id/edit' element={<UserDetailsContainer />} />
            <Route path='/user/create' element={<CreateUserContainer />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  )
}
export default App
