import {LOGGING_IN, LOGOUT, LOGIN_SUCCEDED, LOGIN_FAILED, LOGIN_UNAUTHORIZED, SET_AAD_LOGIN_STATUS} from './loginTypes'
import {IAuth, ILogin} from './loginInterfaces'
import {Dispatch} from 'redux'
import userService from '../../services/userService'
import {Role} from '../../shared/enums/Role'
import { Me } from '../user/interfaces'
export const login = (formValues: ILogin) => async (dispatch: Dispatch) => {
  dispatch({type: LOGGING_IN})
  try {
    const response: any = await userService.login(formValues)

    if (response) {
      const user: IAuth = {...response?.data, isAAD: false }
      if (user && Role[user.role as keyof typeof Role] >= Role.Customer) {
        let auth : IAuth = {
          id: user.id,
          token: user.token,
          isLoggedIn: user.isLoggedIn,
          refreshToken: user.refreshToken,
          expires: user.expires,
          role: user.role,
          permissions: [],
          userName: user.userName,
          isAAD: false,
        }

        localStorage.setItem('auth', JSON.stringify(auth));
        
        const me: Me = await userService.getMe();
        if (!me) {          
          return dispatch({type: LOGIN_FAILED, payload: 'Tjänsten är ej tillgänglig för närvarande.'})
        }
        
        let permissions: string[] = me.permissions
        auth.permissions = permissions;
        localStorage.setItem('auth', JSON.stringify(auth));

        return dispatch({type: LOGIN_SUCCEDED, payload: {auth: user, status: response.status}})
      } else if (user && Role[user.role as keyof typeof Role] < Role.Customer) {
        return dispatch({type: LOGIN_UNAUTHORIZED, payload: {auth: user, status: 403}})
      } else {
        return dispatch({type: LOGIN_UNAUTHORIZED, payload: {auth: undefined, status: response.status}})
      }
    }
    else {
      return dispatch({type: LOGIN_FAILED, payload: 'Tjänsten är ej tillgänglig för närvarande.'})
    }
  } catch (error) {
    return dispatch({type: LOGIN_FAILED, payload: error})
  }
}

export const setAADLoginStatus = (errorMessage?: string | null) => async (dispatch: Dispatch) => dispatch({type: SET_AAD_LOGIN_STATUS, payload: { errorMessage }});

export const logout = (dispatch: Dispatch) => {
  userService.logout()
  return dispatch({type: LOGOUT})
}
