import React, {useEffect} from 'react'
import ReactDOM from 'react-dom'
import {Provider} from 'react-redux'
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import store from './redux/store'
import App from './components/App'
import {SidebarProvider} from './components/shared/SidebarContext'
import ThemeProvider from './shared/theme/ThemeProvider'
import {MsalProvider, useMsal} from '@azure/msal-react'
import msalInstance from './components/auth/msalConfig'

ReactDOM.render(
  <MsalProvider instance={msalInstance}>
    <Provider store={store}>
      <ThemeProvider>
        <SidebarProvider>
          <Router>
            <Routes>
              <Route path='/*' element={<App />} />
            </Routes>
          </Router>
        </SidebarProvider>
      </ThemeProvider>
    </Provider>
  </MsalProvider>,
  document.querySelector('#root')
)
