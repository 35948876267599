import {useState, ChangeEvent, useContext, useEffect} from 'react'
import {Card, Grid, ListItem, List, ListItemText, Divider, Switch, Button, TextField, IconButton, Alert, Collapse} from '@mui/material'
import {ThemeContext} from '../../shared/theme/ThemeProvider'
import {Controller, SubmitHandler, useForm} from 'react-hook-form'
import {SaveResponse} from '../../shared/enums/SaveResponse'
import {useDispatch, useSelector} from 'react-redux'
import {UserPasswordResetResult} from '../../redux/user/interfaces'
import {selfResetCurrentUserActions} from '../../redux/user/actions'
import {Close} from '@mui/icons-material'
import {PASSWORD_UPDATED} from '../../shared/utils/constants'
import { RootState } from '../../redux/rootReducer'

interface SettingsPasswordReset {
  oldPassword: string
  newPassword: string
  confirmPassword: string
}

function NotificationsTab() {
  const {themeName, setThemeName} = useContext(ThemeContext)
  const {register, handleSubmit, control} = useForm<SettingsPasswordReset>()
  const [saveResult, setSaveResult] = useState<UserPasswordResetResult | undefined>()
  const [alertOpen, setAlertOpen] = useState(false)
  const dispatch = useDispatch()
  const [state, setState] = useState({
    hideGraphs: false,
    showVolume: false,
  })

  const updatePasswordResult = useSelector((state: RootState) => state.user.userPasswordReset.result)
  useEffect(() => {
    if (updatePasswordResult?.responseMessage === PASSWORD_UPDATED) {
      setSaveResult(updatePasswordResult)
      setAlertOpen(true)
    }
  }, [updatePasswordResult])

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const settings = {...state, [event.target.name]: event.target.checked}
    setState(settings)
    const storedJson = localStorage.getItem('localSettings')
    if (!storedJson || JSON.stringify(settings) !== storedJson) {
      localStorage.setItem('localSettings', JSON.stringify(settings))
    }
  }

  const onSubmit: SubmitHandler<SettingsPasswordReset> = async (data) => dispatch(selfResetCurrentUserActions(data.oldPassword, data.newPassword, data.confirmPassword))

  useEffect(() => {
    setSaveResult(undefined)
    const storedJson = localStorage.getItem('localSettings')
    if (storedJson) {
      setState(JSON.parse(storedJson))
    }
  }, [])

  const changeTheme = () => {
    themeName === 'DarkTheme' ? setThemeName('DefaultsTheme') : setThemeName('DarkTheme')
  }
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        {saveResult && saveResult.responseMessage === 'PASSWORD_UPDATED' && (
          <Collapse in={alertOpen}>
            <Alert
              severity='success'
              action={
                <IconButton
                  aria-label='close'
                  color='inherit'
                  size='small'
                  onClick={() => {
                    setAlertOpen(false)
                  }}>
                  <Close fontSize='inherit' />
                </IconButton>
              }
              sx={{mb: 2}}>
              Ditt lösenord har uppdaterats.
            </Alert>
          </Collapse>
        )}
        <Card>
          <List>
            <ListItem sx={{p: 3}}>
              <ListItemText
                primaryTypographyProps={{variant: 'h5', gutterBottom: true}}
                secondaryTypographyProps={{
                  variant: 'subtitle2',
                  lineHeight: 1,
                }}
                primary='Förbrukningsgrafer'
                secondary='Dölj förbrukningsgrafer'
              />
              <Switch color='primary' checked={state.hideGraphs} onChange={handleChange} name='hideGraphs' />
            </ListItem>
            <Divider component='li' />
            <ListItem sx={{p: 3}}>
              <ListItemText
                primaryTypographyProps={{variant: 'h5', gutterBottom: true}}
                secondaryTypographyProps={{
                  variant: 'subtitle2',
                  lineHeight: 1,
                }}
                primary='Enhetstyp för vatten'
                secondary='Visa enhetstyp för vatten som m3 istället för liter vid visning av LOA och ATEMP.'
              />
              <Switch color='primary' checked={state.showVolume} onChange={handleChange} name='showVolume' />
            </ListItem>
            <Divider component='li' />
            <ListItem sx={{p: 3}}>
              <ListItemText
                primaryTypographyProps={{variant: 'h5', gutterBottom: true}}
                secondaryTypographyProps={{
                  variant: 'subtitle2',
                  lineHeight: 1,
                }}
                primary='Mörk/ljusläge'
                secondary='Ställ in appens utseende i mörkt eller ljust läge'
              />
              <Switch color='primary' defaultChecked={themeName === 'DarkTheme' ? true : false} onChange={changeTheme} />
            </ListItem>
            <Divider component='li' />
            <ListItem sx={{p: 3}}>
              <ListItemText
                primaryTypographyProps={{variant: 'h5', gutterBottom: true}}
                secondaryTypographyProps={{
                  variant: 'subtitle2',
                  lineHeight: 1,
                }}
                primary='Lösenordsåterställning'
                secondary='Återställ mitt lösenord'
              />
              <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                  name='oldPassword'
                  control={control}
                  defaultValue=''
                  render={({field}) => <TextField {...field} type='password' label='Gammalt lösenord' variant='outlined' sx={{marginLeft: '8px'}} />}
                />
                <Controller
                  name='newPassword'
                  control={control}
                  defaultValue=''
                  render={({field}) => <TextField {...field} type='password' label='Nytt lösenord' variant='outlined' sx={{marginLeft: '8px'}} />}
                />
                <Controller
                  name='confirmPassword'
                  control={control}
                  defaultValue=''
                  render={({field}) => <TextField {...field} type='password' label='Bekräfta nytt lösenord' variant='outlined' sx={{marginLeft: '8px'}} />}
                />
                <Button variant='contained' type='submit' color='primary' sx={{marginLeft: '8px', marginTop: '8px'}}>
                  Återställ
                </Button>
              </form>
            </ListItem>
          </List>
        </Card>
      </Grid>
    </Grid>
  )
}

export default NotificationsTab
