import {Close} from '@mui/icons-material'
import {Alert, Box, Grid, IconButton, Stack, Typography} from '@mui/material'
import React, {FC, useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {Navigate} from 'react-router-dom'
import {RootState} from '../../redux/rootReducer'
import {isAuthorized, isNullOrWhiteSpace, isTokenExpired} from '../../shared/utils/utilities'
import LoginForm from './LoginForm'
import {LoginContainer, ImageLogo, LoginBox} from './LoginStyles'
import background from './bg.svg'
const Login: FC = () => {
  const auth = useSelector((state: RootState) => state.login.auth)
  const status = useSelector((state: RootState) => state.login.status)
  const aadErrorMessage = useSelector((state: RootState) => state.login.aadErrorMessage)  
  const [showAlert, setShowAlert] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null) 
  let isInAuthorizedRole = isAuthorized()

  useEffect(() => {
    setErrorMessage(null)
  }, [])

  useEffect(() => {
    if (aadErrorMessage) {
      setErrorMessage(aadErrorMessage)
    }
  }, [aadErrorMessage])

  useEffect(() => {    
    if(status && (status == 401 || -1)) setShowAlert(true)    
    return () => {
      setShowAlert(false)
    }
  }, [status])

  const renderLoginError = (status: number | undefined) => {  
    if(!status) return 'Inloggningen misslyckades.'
    switch (status) {
      case 401:
        return 'Felaktigt användarnamn eller lösenord'
      case 403:
        return 'Åtkomst saknas.'
      case -1:
        return 'Inloggningen misslyckades.'
      default:
        return 'Något gick fel, försök igen senare'
    }
  }

  if (!isTokenExpired() && isInAuthorizedRole && auth) return <Navigate to='/home' />
  return (
    <div
      className='login-page'
      style={{
        backgroundImage: `url(${background})`,        
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',        
        height: '100%'
      }}>
      <LoginContainer maxWidth='xs'>
        <Grid>
          <Grid container direction='row' spacing={1}>
            <LoginBox>
              <Stack direction='row' spacing={2}>
                <ImageLogo>
                  <img className='login-form-logo' src='/assets/logo/saveup.logo.svg' alt='' />
                </ImageLogo>             
                <Typography variant='h3' component='h3' gutterBottom>
                  Admin
                </Typography>
              </Stack>
              <LoginForm />
            </LoginBox>
          </Grid>
          <Grid container direction='row' spacing={1}>
            <Grid item xs={12}>
              {showAlert ? (
                <Alert
                  severity='error'
                  action={
                    <IconButton
                      aria-label='close'
                      color='inherit'
                      size='small'
                      onClick={() => {
                        setShowAlert(false)
                      }}>
                      <Close fontSize='inherit' />
                    </IconButton>
                  }
                  sx={{mb: 2, width: '410px'}}>
                  {renderLoginError(status)}
                </Alert>
              ) : (
                ''
              )}
              {aadErrorMessage && !isNullOrWhiteSpace(aadErrorMessage) ? (
                <Alert
                  severity='error'
                  action={
                    <IconButton
                      aria-label='close'
                      color='inherit'
                      size='small'
                      onClick={() => {
                        setShowAlert(false)
                      }}>
                      <Close fontSize='inherit' />
                    </IconButton>
                  }
                  sx={{mb: 2, width: '410px'}}>
                  {aadErrorMessage}
                </Alert>
              ) : (
                ''
              )}
            </Grid>
          </Grid>
        </Grid>
      </LoginContainer>
    </div>
  )
}

export default Login
